import React from "react";
import tw from "twin.macro";

import Container from "./Container";
import { LinkComponent } from "./Link";

const FooterTW = tw.footer`
  flex
  flex-wrap
  gap-y-16
  gap-x-30
  justify-between
  py-40
  border-t
  border-white
  border-opacity-20
`;

class Footer extends React.Component {
  render() {
    return (
      <Container>
        <FooterTW>
          <p>Dotazy pište na <LinkComponent to="mailto:info@nazavody.cz" title="info@nazavody.cz" /></p>
          <p>naZavody.cz s.r.o. &copy; {new Date().getFullYear()}</p>
        </FooterTW>
      </Container>
    )
  }
};

export default Footer;
