import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet"

import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";

type Props = {
  children: any;
};

const LayoutTW = tw.div`
  min-h-screen
  bg-mainGradient
  flex
  flex-col
  gap-60
  text-white
  leading-base
  sm:gap-40
`;

const Main = tw.main`
  grid
  items-center
  flex-1
`;

export const Layout: React.ComponentType<Props> = ({ children }) => {
  const title = "MeetUp 12.2.2022 | naZávody.cz";
  const description = "MeetUp organizátorů závodů a sportovních akcí 12. února v Kozlovně Skleník v České Lípě."

  return (
    <LayoutTW>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://meetup.nazavody.cz/og-facebook.png"
        />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://meetup.nazavody.cz/og-twitter.png"
        />
        <meta name="twitter:image:alt" content={description} />
        <meta name="twitter:site" content="@nazavodycz" />
        <meta name="twitter:creator" content="@nazavodycz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#9F3EF5" />
        <meta name="msapplication-TileColor" content="#9F3EF5" />
        <meta name="theme-color" content="#9F3EF5" />
      </Helmet>
      <Header />
      <Main>
        <Container>
          { children }
        </Container>
      </Main>
      <Footer />
    </LayoutTW>
  )
};
