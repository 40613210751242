import styled from "@emotion/styled";
import React from "react";
import tw from "twin.macro";

type Props = {
  children: any;
};

const HeadingH1Styled = styled.h1`
  span {
    ${tw`
      block
      font-normal
      text-21
      sm:text-30
    `}
  }
`;

const HeadingH1 = tw(HeadingH1Styled)`
  mb-16
  text-30
  sm:text-40
  font-bold
  leading-heading
`;

const HeadingH2 = tw.h2`
  text-21
  font-bold
  leading-heading
  mb-heading
`;

const HeadingH3 = tw.h3`
  text-18
  font-bold
  leading-heading
  mb-heading
`;

export const H1: React.ComponentType<Props> = ({ children }) => {
  return (
    <HeadingH1>{children}</HeadingH1>
  )
};

export const H2: React.ComponentType<Props> = ({ children }) => {
  return (
    <HeadingH2>{children}</HeadingH2>
  )
};

export const H3: React.ComponentType<Props> = ({ children }) => {
  return (
    <HeadingH3>{children}</HeadingH3>
  )
};
