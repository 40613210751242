import React from "react";
import tw from "twin.macro";
import { Link } from "gatsby";

import Logo from "../images/logo.svg";
import Container from "./Container";
import { LinkComponent } from "./Link";

const HeaderTW = tw.header`
  flex
  flex-wrap
  justify-between
  items-end
  pt-40
  gap-16
  sm:gap-40
`;

const HeaderLogo = tw.img``;

const HeaderLink = tw(LinkComponent)`
  order-first
  w-full
  sm:order-last
  sm:w-auto
`;

class Header extends React.Component {
  render() {
    return (
      <Container>
        <HeaderTW>
          <Link to="/">
            <HeaderLogo src={Logo} alt="MeetUp.naZávody.cz" width="253" height="32" />
          </Link>
          <HeaderLink to="https://nazavody.cz" title="zpět na&nbsp;hlavní web" backIcon />
        </HeaderTW>
      </Container>
    )
  }
};

export default Header;
