import tw from "twin.macro";

const Container = tw.div`
  w-full
  max-w-container
  mx-auto
  px-16
  sm:max-w-containerSM
  sm:px-30
`;

export default Container;
