import * as React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import BackIcon from "../images/back.svg";
import { Link } from "gatsby";

type Props = {
  className?: string;
  to: string;
  title: string;
  openInNewWindow?: boolean;
  backIcon?: boolean;
};

type LinkTitleProps = {
  backIcon?: boolean;
}

const LinkTW = tw.a``;

const LinkTitleStyled = styled.span<LinkTitleProps>`
  ${({ backIcon }) =>  backIcon ? tw`font-bold` : tw`font-normal`};
`;

const LinkTitle = tw(LinkTitleStyled)`
  inline-block
  underline
  group-hover:no-underline
`;

const LinkIcon = tw.img`
  inline-block
  ml-8
`;

export const LinkComponent: React.ComponentType<Props> = ({
  to,
  title,
  openInNewWindow,
  backIcon,
  className 
}) => {
  return (
    <LinkTW
      className={(className ? className + " group" : "group")}
      href={to}
      target={openInNewWindow ? "_blank" : "_self"}
      rel={openInNewWindow ? "noopener noreferrer" : ""}
    >
      <LinkTitle>
        {title}
      </LinkTitle>
      {backIcon && (
        <LinkIcon src={BackIcon} alt="Ikona zpět" width="14" height="11" />
      )}
    </LinkTW>
  );
};

export const LinkGatsbyComponent: React.ComponentType<Props> =({
  to,
  title,
  backIcon
}) => {
  return (
    <Link
      to={to}
      className="group"
    >
      <LinkTitle>
        {title}
      </LinkTitle>
      {backIcon && (
        <LinkIcon src={BackIcon} alt="Ikona zpět" width="14" height="11" />
      )}
    </Link>
  );
};
